<template>
  <CRow>
    <CCol col="12">
      <!--CToaster :autohide="8000">
        <template v-for="(alert, index) in alerts">
          <CToast :show="true" :color="alert.type" :key="index"> {{ $t(alert.message) }} </CToast>
        </template>
      </CToaster-->

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit invoice') + ` id: ${form.id}` : $t('Create invoice') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard title="Identification and Location">
              <AMultiSelect :horizontal="{ input: 'col-sm-5', label: 'col-sm-3' }" label="Class" v-model="form.invoice_class_id" :options="lists.invoice_classes" :isValid="isValid('invoice_class_id')" :errors="getErrors('invoice_class_id')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-5', label: 'col-sm-3' }" label="Visual" v-model="form.invoice_type_id" :options="lists.invoice_types" :isValid="isValid('invoice_type_id')" :errors="getErrors('invoice_type_id')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Show in form" v-model="form.in_form" :options="lists.in_form_options" :isValid="isValid('in_form')" :errors="getErrors('in_form')" />
              <ACheckBox label="Generar intereses" placeholder="Generar intereses al tener capital vencido." v-model="form.interest" />

              <hr />

              <AInput v-if="!form.id" name="id" :horizontal="{ input: 'col-sm-2' }" type="number" label="ID" v-model="form._id" :isValid="isValid('id')" :errors="getErrors('id')" />
              <AInput label="Name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />

              <div v-if="lists.invoices.length">
                <hr />
                <AMultiSelect :horizontal="{ input: 'col-sm-5' }" label="Order" v-model="form.order_id" :options="lists.in_order_options" @select="onOrderChanged" />
                <AMultiSelect v-show="form.order_id === 'PE'" :horizontal="{ input: 'col-sm-5' }" label="After" v-model="form.after" :options="custom_order" @select="onOrderChanged" />
              </div>
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import * as utils from '@/app/_utils/global-utils'
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'InvoiceForm',
  mixins: [formMixin],

  computed: {
    custom_order() {
      //return this.lists.invoices.filter(item => item.order !== this.form.order)
      return utils.order(this.lists.invoices, 'order') //.filter(item => item.order !== this.form.order)
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, contacts: [] },

      // Extra
      lists: {
        invoices: [],
        invoice_types: [],
        invoice_classes: [],
        in_form_options: [],
        in_order_options: []
      },

      // Helpers
      alerts: [],
      //cropper: { file: '', type: '', modal: false, media: {}, options: {} },
      validators: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/invoices' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.invoice ? data.invoice : { id: 0 }
      this.lists = data._lists || {}
      this.lists.in_form_options = [
        { id: 0, name: 'No mostrar' },
        { id: 1, name: 'Mostrar con valor del mes anterior' },
        { id: 2, name: 'Mostrar con valor en cero ($0)' }
      ]
      this.lists.in_order_options = [
        { id: 'PR', name: 'Primero' },
        { id: 'UL', name: 'Último' },
        { id: 'PE', name: 'Personalizado' }
      ]

      this.parseView()
      this.parseOrder()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Invoice']: options })
    },
    parseOrder() {
      this.$set(this.form, 'after', '')
      //this.$set(this.form, 'order_id', 'UL')
      if (this.form.id) {
        let order_id = 'PE'
        if (this.form.order === this.lists.invoices.length - 1) order_id = 'UL'
        if (this.form.order === 1) order_id = 'PR'

        this.$set(this.form, 'order_id', order_id)
        if (order_id === 'PE' && this.form.order > 1 && this.lists.invoices.length > 1) {
          const after = this.lists.invoices.find(item => item.order === this.form.order - 1) || { id: 0 }
          this.$set(this.form, 'after', after.id)
        }
      }
    },

    onOrderChanged() {
      this.form.order = 0
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      if (!this.form.id) {
        if (!this.form._id) {
          return this.setErrors({ id: ['ID es requerido.'] })
        }
        if (this.lists.invoices.find(item => item.id === parseInt(this.form._id))) {
          return this.setErrors({ id: ['El ID ya existe.'] })
        }
      }

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/invoices', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/invoicing/invoices/${response.data.invoice.id}/edit`).catch(() => {})
          self.showAlert(`Invoice ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the invoice.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
